import * as ActionTypes from '../../constants/ActionTypes';

export const openBookingModal = (state) => {
    return {
        type: ActionTypes.OPEN_BOOKING_MODAL,
        payload: state
    }
}

export const resetBooking = () => {
    console.log('Reset Booking');
    return {
        type: ActionTypes.RESET_BOOKING
    }
}

export const addBooking = (booking) => {
    return {
      type: ActionTypes.ADD_BOOKING,
      booking
    }
}

export const addBookingSuccess = (bookings) => {
    return {
      type: ActionTypes.ADD_BOOKING_SUCCESS,
      payload: bookings
    }
}

export const addBookingError = (error) => {
    return {
      type: ActionTypes.ADD_BOOKING_ERROR,
      payload: error
    }
}

export const fetchBookings = () => {
    return {
        type: ActionTypes.FETCH_BOOKINGS
    }
}

export const fetchBookingsSuccess = (bookings) => {
    return {
        type: ActionTypes.FETCH_BOOKINGS_SUCCESS,
        payload: bookings
    }
}

export const fetchBookingsError = () => {
    return {
        type: ActionTypes.FETCH_BOOKINGS_ERROR
    }
}

export const deleteBooking = (booking) => {
  console.log(booking);
  return {
    type: ActionTypes.DELETE_BOOKING,
    payload: booking
  }
}

export const deleteBookingSuccess = (bookings) => {
  return {
    type: ActionTypes.DELETE_BOOKING_SUCCESS,
    payload: bookings
  }
}

export const deleteBookingError = (message) => {
  return {
    type: ActionTypes.DELETE_BOOKING_ERROR,
    payload: message
  }
}
