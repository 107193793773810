import {
  FETCH_SALES,
  FETCH_SALES_SUCCESS,
  FETCH_SALES_ERROR,
  FETCH_SALES_REPORT,
  FETCH_SALES_REPORT_SUCCESS,
  FETCH_SALES_REPORT_ERROR
} from '../../constants/ActionTypes';
import { message } from 'antd';

const INIT_STATE = {
  pending: false,
  sales_list: [],
  sales_report: []
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SALES:
      return { ...state, pending: true }

    case FETCH_SALES_SUCCESS:
      return { ...state, sales_list: payload, pending: false }

    case FETCH_SALES_ERROR:
      message.error('Failed to retrieve orders');
      return { ...state, sales_list: [], pending: false }

    case FETCH_SALES_REPORT:
      return { ...state, pending: true }

    case FETCH_SALES_REPORT_SUCCESS:
      return {
        ...state,
        sales_report: {
          ...payload,
          total_merchandise_sales: [payload.total_merchandise_sales],
          total_sales: [payload.total_sales]
        },
        pending: false
      }

    case FETCH_SALES_REPORT_ERROR:
      message.error('Failed to retrieve orders');
      return { ...state, sales_report: [], pending: false }

    default:
      return state;
  }
}