import * as ActionTypes from '../../constants/ActionTypes';

export const fetchCoupons = () => {
  return {
    type: ActionTypes.FETCH_COUPONS
  }
}

export const fetchCouponsSuccess = (coupons) => {
  return {
    type: ActionTypes.FETCH_COUPONS_SUCCESS,
    payload: coupons
  }
}

export const fetchCouponsError = (error) => {
  return {
    type: ActionTypes.FETCH_COUPONS_ERROR,
    payload: error
  }
}

export const openCouponModal = (state) => {
  return {
    type: ActionTypes.OPEN_COUPON_MODAL,
    payload: state
  }
}

export const resetCoupon = () => {
  console.log('Reset Coupon');
  return {
    type: ActionTypes.RESET_COUPON
  }
}

export const addCoupon = (coupon) => {
  return {
    type: ActionTypes.ADD_COUPON,
    coupon
  }
}

export const addCouponSuccess = (coupons) => {
  return {
    type: ActionTypes.ADD_COUPON_SUCCESS,
    payload: coupons
  }
}

export const addCouponError = (error) => {
  return {
    type: ActionTypes.ADD_COUPON_ERROR,
    payload: error
  }
}

export const retireCoupon = (coupon) => {
  console.log(coupon);
  return {
    type: ActionTypes.RETIRE_COUPON,
    payload: coupon
  }
}

export const retireCouponSuccess = (coupons) => {
  return {
    type: ActionTypes.RETIRE_COUPON_SUCCESS,
    payload: coupons
  }
}

export const retireCouponError = (error) => {
  return {
    type: ActionTypes.RETIRE_COUPON_ERROR,
    payload: error
  }
}
