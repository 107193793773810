import * as ActionTypes from '../../constants/ActionTypes';

export const fetchMembers = (searchStr) => {
  return {
    type: ActionTypes.FETCH_MEMBERS,
    payload: searchStr
  }
}

export const fetchMembersSuccess = (members) => {
  return {
    type: ActionTypes.FETCH_MEMBERS_SUCCESS,
    payload: members
  }
}

export const fetchMembersError = (error) => {
  return {
    type: ActionTypes.FETCH_MEMBERS_ERROR,
    payload: error
  }
}

export const openMemberModal = (state) => {
  return {
    type: ActionTypes.OPEN_MEMBER_MODAL,
    payload: state
  }
}

export const resetMember = () => {
  console.log('Reset Member');
  return {
    type: ActionTypes.RESET_MEMBER
  }
}

export const addMember = (member) => {
  return {
    type: ActionTypes.ADD_MEMBER,
    member
  }
}

export const addMemberSuccess = (members) => {
  return {
    type: ActionTypes.ADD_MEMBER_SUCCESS,
    payload: members
  }
}

export const addMemberError = (error) => {
  return {
    type: ActionTypes.ADD_MEMBER_ERROR,
    payload: error
  }
}

export const editMember = (query) => {
  return {
    type: ActionTypes.EDIT_MEMBER,
    payload: query
  }
}

export const editMemberSuccess = (member) => {
  return {
    type: ActionTypes.EDIT_MEMBER_SUCCESS,
    payload: member
  }
}

export const editMemberError = (error) => {
  return {
    type: ActionTypes.EDIT_MEMBER_ERROR,
    payload: error
  }
}

export const updateMember = (member) => {
  console.log(member);
  return {
    type: ActionTypes.UPDATE_MEMBER,
    payload: member
  }
}

export const updateMemberSuccess = (members) => {
  return {
    type: ActionTypes.UPDATE_MEMBER_SUCCESS,
    payload: members
  }
}

export const updateMemberError = (error) => {
  return {
    type: ActionTypes.UPDATE_MEMBER_ERROR,
    payload: error
  }
}

export const deleteMember = (member) => {
  console.log(member);
  return {
    type: ActionTypes.DELETE_MEMBER,
    payload: member
  }
}

export const deleteMemberSuccess = (members) => {
  return {
    type: ActionTypes.DELETE_MEMBER_SUCCESS,
    payload: members
  }
}

export const deleteMemberError = (error) => {
  return {
    type: ActionTypes.DELETE_MEMBER_ERROR,
    payload: error
  }
}

export const resetMemberPasses = () => {
  return {
    type: ActionTypes.RESET_MEMBER_PASSES
  }
}

export const openMemberPassesModal = (state) => {
  return {
    type: ActionTypes.OPEN_MEMBER_PASSES_MODAL,
    payload: state
  }
}

export const editMemberPasses = (query) => {
  return {
    type: ActionTypes.EDIT_MEMBER_PASSES,
    payload: query
  }
}

export const editMemberPassesSuccess = (passes) => {
  return {
    type: ActionTypes.EDIT_MEMBER_PASSES_SUCCESS,
    payload: passes
  }
}

export const editMemberPassesError = (error) => {
  return {
    type: ActionTypes.EDIT_MEMBER_PASSES_ERROR,
    payload: error
  }
}

export const updateMemberTicket = (ticket) => {
  return {
    type: ActionTypes.UPDATE_MEMBER_TICKET,
    payload: ticket
  }
}

export const updateMemberTicketSuccess = (passes) => {
  return {
    type: ActionTypes.UPDATE_MEMBER_TICKET_SUCCESS,
    payload: passes
  }
}

export const updateMemberTicketError = (error) => {
  return {
    type: ActionTypes.UPDATE_MEMBER_TICKET_ERROR,
    payload: error
  }
}

export const requestOSS = () => {
  return {
    type: ActionTypes.GET_OSS_TOKEN
  }
}

export const getOSStokenSuccess = (cred) => {
  return {
    type: ActionTypes.GET_OSS_TOKEN_SUCCESS,
    payload: cred
  }
}