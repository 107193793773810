import axios from "axios";
import * as ActionTypes from '../../constants/ActionTypes';
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { addBookingError, addBookingSuccess, fetchBookingsSuccess, fetchBookingsError, deleteBookingSuccess, deleteBookingError } from "../actions/Bookings";

function* addBookingRequest({ booking }) {
    console.log('Add Booking called');
    try {
        const resp = yield call(axios.post, '/v1/admin/create_booking', booking);
        yield put(addBookingSuccess(resp.data));
    } catch (error) {
        console.log(error.response);
        yield put(addBookingError(error.response.data));
    }
}

export function* addBooking() {
    yield takeLatest(ActionTypes.ADD_BOOKING, addBookingRequest);
}

function* fetchBookingsRequest() {
    try {
        const resp = yield call(axios.post, '/v1/admin/bookings');
        yield put(fetchBookingsSuccess(resp.data));
        console.log(resp.data);
    } catch (error) {
        console.log(error.response);
        yield put(fetchBookingsError(error.response.data));
    }
}

export function* fetchBookings() {
    yield takeLatest(ActionTypes.FETCH_BOOKINGS, fetchBookingsRequest);
}

function* deleteBookingRequest({ payload }) {
    console.log('Delete Booking called', payload);
    try {
      const resp = yield call(axios.post, '/v1/admin/cancel_booking', payload);
      console.log(resp);
      yield put(deleteBookingSuccess(resp.data));
    } catch (error) {
      console.log(error.response);
      yield put(deleteBookingError(error.response ? error.response.data : 'Server not responding'));
    }
}

export function* deleteBooking() {
  yield takeLatest(ActionTypes.DELETE_BOOKING, deleteBookingRequest);
}

export default function* rootSaga() {
    yield all([
        fork(addBooking),
        fork(fetchBookings),
        fork(deleteBooking)
    ]);
}
