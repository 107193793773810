import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { getTicketSuccess, getTicketError, fetchTicketsSuccess, fetchTicketsError, retrievePhotoSuccess, retrievePhotoError, freezeTicketSuccess, freezeTicketError } from '../actions/Ticket';

function* getTicketRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_ticket', payload);
    yield put(getTicketSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(getTicketError(error.response.data));
  }
}

function* freezeTicketRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/freeze_ticket', payload);
    yield put(freezeTicketSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(freezeTicketError(error.response.data));
  }
}

function* fetchTicketsRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/fetch_tickets', payload);
    yield put(fetchTicketsSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(fetchTicketsError(error.response.data));
  }
}

function* retrievePhotoRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v2/admin/download', payload);
    yield put(retrievePhotoSuccess(resp.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(retrievePhotoError(error.response.data));
  }
}

export function* getTicket() {
  yield takeLatest(ActionTypes.GET_TICKET, getTicketRequest);
}

export function* freezeTicket() {
  yield takeLatest(ActionTypes.FREEZE_TICKET, freezeTicketRequest);
}

export function* fetchTickets() {
  yield takeLatest(ActionTypes.FETCH_TICKETS, fetchTicketsRequest);
}

export function* retrievePhoto() {
  yield takeLatest(ActionTypes.RETRIEVE_PHOTO, retrievePhotoRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getTicket),
    fork(fetchTickets),
    fork(retrievePhoto),
    fork(freezeTicket)
  ]);
}
