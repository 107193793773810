import { message } from "antd";
import _ from 'lodash';
import { ADD_POST, ADD_POST_ERROR, ADD_POST_SUCCESS, DELETE_POST, DELETE_POST_SUCCESS, DELETE_POST_ERROR, EDIT_POST_ERROR, EDIT_POST_SUCCESS, FETCH_POSTS, FETCH_POSTS_ERROR, FETCH_POSTS_SUCCESS, OPEN_POST_MODAL, RESET_POST, UPDATE_POST, UPDATE_POST_ERROR, UPDATE_POST_SUCCESS } from "../../constants/ActionTypes";

const default_post = {
    _id: null,
    tag: null,
	title: null,
	content: null,
	start_date: null,
	end_date: null,
	publish: false
}

const INIT_STATE = {
    pending: false,
    refresh: false,
    edit: false,
    post_modal_open: false,
    post: default_post,
    posts: []
}

export default (state = INIT_STATE, { type, payload }) => {
    let item;

    switch (type) {
        case FETCH_POSTS:
            return { ...state, pending: true,  refresh: true }

        case FETCH_POSTS_SUCCESS:
            const posts = payload.map(item =>{
                item.start_date = new Date(item.start_date);
                item.end_date = new Date(item.end_date);
                return item;
            });
            return { ...state, pending: false, posts: payload, refresh: false }

        case FETCH_POSTS_ERROR:
            return { ...state, pending: false,  refresh: false }

        case RESET_POST:
            return { ...state, pending: false, post: {...default_post}, edit: false, post_modal_open: false }

        case OPEN_POST_MODAL:
            return { ...state, post_modal_open: payload }
        
        case ADD_POST:
            return { ...state, pending: true }

        case ADD_POST_SUCCESS:
            message.success('New post added successfully');
            return { ...state, pending: false, post_modal_open: false,  refresh: true }

        case ADD_POST_ERROR:
            message.error(payload);
            return { ...state, pending: false }

        case EDIT_POST_SUCCESS:
            console.log(payload);
            item = state.posts.find((el) => el._id === payload._id);
            payload.key = payload._id;
            if (_.isEqual(item, payload)) {
                return {
                    ...state,
                    post: { ...payload },
                    pending: false,
                    edit: true,
                    post_modal_open: true
                }
            } else {
                return {
                    ...state,
                    posts: state.posts.map(el => {
                        if (el._id === payload._id) {
                            return ({ key: el._id, ...payload })
                        } else {
                            return ({ key: el._id, ...el })
                        }
                    }),
                    post: { ...payload },
                    pending: false,
                    edit: true,
                    post_modal_open: true
                }
            }

        case UPDATE_POST:
            console.log('Update Post Reducer');
            console.log(payload);
            return { ...state, pending: true }

        case UPDATE_POST_SUCCESS:
            message.success('Post updated successfully');
            return { ...state, posts: payload.map(el => ({ key: el._id, ...el })), post: { ...default_post }, pending: false, post_modal_open: false, edit: false }

        case DELETE_POST:
            console.log('Delete Post Reducer');
            console.log(payload);
            return { ...state, pending: true }

        case DELETE_POST_SUCCESS:
            message.success('Post deleted successfully');
            return { ...state, posts: payload.map(el => ({ key: el._id, ...el })), post: { ...default_post }, pending: false, post_modal_open: false, edit: false,  refresh: true }

        case EDIT_POST_ERROR:
        case UPDATE_POST_ERROR:
        case DELETE_POST_ERROR:
            message.error(payload);
            return { ...state, pending: false }

        default:
            return state;
    }
}
