import * as ActionTypes from '../../constants/ActionTypes';

export const fetchAttendance = (query) => {
  return {
    type: ActionTypes.FETCH_ATTENDANCE,
    payload: query
  }
}

export const fetchAttendanceSuccess = (attendance) => {
  return {
    type: ActionTypes.FETCH_ATTENDANCE_SUCCESS,
    payload: attendance
  }
}

export const fetchAttendanceError = () => {
  return {
    type: ActionTypes.FETCH_ATTENDANCE_ERROR,
  }
}

export const updateAttendance = (attendance) => {
  console.log(attendance);
  return {
    type: ActionTypes.UPDATE_ATTENDANCE,
    payload: attendance
  }
}

export const updateAttendanceSuccess = (attendance) => {
  return {
    type: ActionTypes.UPDATE_ATTENDANCE_SUCCESS,
    payload: attendance
  }
}

export const updateAttendanceError = (message) => {
  return {
    type: ActionTypes.UPDATE_ATTENDANCE_ERROR,
    payload: message
  }
}

export const exportAttendance = (params) => {
  return {
    type: ActionTypes.EXPORT_ATTENDANCE,
    payload: params
  }
}

export const exportAttendanceSuccess = () => {
  return {
    type: ActionTypes.EXPORT_ATTENDANCE_SUCCESS,
  }
}

export const exportAttendanceError = (error) => {
  return {
    type: ActionTypes.EXPORT_ATTENDANCE_ERROR,
    payload: error
  }
}

export const editAttendance = (query) => {
  return {
    type: ActionTypes.EDIT_ATTENDANCE,
    payload: query
  }
}

export const editAttendanceSuccess = (attendance) => {
  return {
    type: ActionTypes.EDIT_ATTENDANCE_SUCCESS,
    payload: attendance
  }
}

export const editAttendanceError = () => {
  return {
    type: ActionTypes.EDIT_ATTENDANCE_ERROR,
  }
}

export const resetAttendance = () => {
  return {
    type: ActionTypes.RESET_ATTENDANCE
  }
}

export const openAttendanceModal = (state) => {
  return {
    type: ActionTypes.OPEN_ATTENDANCE_MODAL,
    payload: state
  }
}
