import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchAttendanceSuccess, updateAttendanceSuccess, updateAttendanceError, exportAttendanceSuccess, exportAttendanceError, editAttendanceSuccess, editAttendanceError } from '../actions/Attendance';
import fileDownload from 'js-file-download';

function* fetchAttendanceRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_attendance', payload);
    yield put(fetchAttendanceSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

function* updateAttendanceRequest({ payload }) {
  console.log('Update Attendance called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_attendance', payload);
    console.log(resp);
    yield put(updateAttendanceSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateAttendanceError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportAttendanceRequest({ payload }) {
  console.log('Export Attendance called', payload);
  try {
    const resp = yield call(axios, {
      method: 'post',
      url: '/v1/admin/export_attendance',
      data: payload,
      responseType: 'arraybuffer'
    });

    console.log(resp);

    fileDownload(resp.data, payload.filename);

    yield put(exportAttendanceSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportAttendanceError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editAttendanceRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_edit_attendance', payload);
    let data;
    if (resp.data && resp.data.length > 0)
      data = resp.data[0];
    else
      throw new Error(`${payload._id} not found`);
    yield put(editAttendanceSuccess(data));
  } catch (error) {
    console.log(error.response);
    yield put(editAttendanceError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchAttendance() {
  yield takeLatest(ActionTypes.FETCH_ATTENDANCE, fetchAttendanceRequest);
}

export function* updateAttendance() {
  yield takeLatest(ActionTypes.UPDATE_ATTENDANCE, updateAttendanceRequest);
}

export function* exportAttendance() {
  yield takeLatest(ActionTypes.EXPORT_ATTENDANCE, exportAttendanceRequest);
}

export function* editAttendance() {
  yield takeLatest(ActionTypes.EDIT_ATTENDANCE, editAttendanceRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAttendance),
    fork(updateAttendance),
    fork(exportAttendance),
    fork(editAttendance)
  ]);
}

