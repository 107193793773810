import axios from "axios";
import * as ActionTypes from '../../constants/ActionTypes';
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { addPostError, addPostSuccess, deletePostError, deletePostSuccess, editPostError, editPostSuccess, fetchPostsError, fetchPostsSuccess, updatePostError, updatePostSuccess } from "../actions/Posts";

function* addPostRequest({ post }) {
    console.log('Add Post called');
    try {
        const resp = yield call (axios.post, '/v1/admin/add_post', post);
        yield put(addPostSuccess(resp.data));
    } catch (error) {
        console.log(error.response);
        yield put(addPostError(error.response.data));
    }
}

export function* addPost() {
    yield takeLatest(ActionTypes.ADD_POST, addPostRequest);
}

function* fetchPostsRequest() {
    try {
        const resp = yield call (axios.post, '/v1/admin/posts');
        yield put(fetchPostsSuccess(resp.data));
        console.log(resp.data);
    } catch (error) {
        console.log(error.response);
        yield put(fetchPostsError(error.response.data));
    }
}

export function* fetchPosts() {
    yield takeLatest(ActionTypes.FETCH_POSTS, fetchPostsRequest);
}

function* editPostRequest({ payload }) {
    try {
        const resp = yield call(axios.post, '/v1/admin/get_post', payload);
        yield put(editPostSuccess(resp.data));
    } catch (error) {
        console.log(error.response);
        yield put(editPostError(error.response ? error.response.data : 'Server not responding'));
      }
}

export function* editPost() {
    yield takeLatest(ActionTypes.EDIT_POST, editPostRequest);
}

function* updatePostRequest({ payload }) {
    console.log('Update Post called', payload);
    try {
        const resp = yield call(axios.post, '/v1/admin/update_post', payload);
        console.log(resp);
        yield put(updatePostSuccess(resp.data));
    } catch (error) {
        console.log(error.response);
        yield put(updatePostError(error.response ? error.response.data : 'Server not responding'));
    }
}

export function* updatePost() {
    yield takeLatest(ActionTypes.UPDATE_POST, updatePostRequest);
}

function* deletePostRequest({ payload }) {
    console.log('Delete Post called', payload);
    try {
        const resp = yield call(axios.post, '/v1/admin/delete_post', payload);
        console.log(resp);
        yield put(deletePostSuccess(resp.data));
    } catch (error) {
        console.log(error.response);
        yield put(deletePostError(error.response ? error.response.data : 'Server not responding'));
    }
}

export function* deletePost() {
    yield takeLatest(ActionTypes.DELETE_POST, deletePostRequest);
}

export default function* rootSaga() {
    yield all([
        fork(addPost),
        fork(fetchPosts),
        fork(editPost),
        fork(updatePost),
        fork(deletePost)
    ]);
}