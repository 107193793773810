import * as ActionTypes from '../../constants/ActionTypes';

export const openPostModal = (state) => {
    return {
        type: ActionTypes.OPEN_POST_MODAL,
        payload: state
    }
}

export const resetPost = () => {
    console.log('Reset Post');
    return {
        type: ActionTypes.RESET_POST
    }
}

export const addPost = (post) => {
    return {
        type: ActionTypes.ADD_POST,
        post
    }
}

export const addPostSuccess = (posts) => {
    return {
        type: ActionTypes.ADD_POST_SUCCESS,
        payload: posts
    }
}

export const addPostError = (error) => {
    return {
        type: ActionTypes.ADD_POST_ERROR,
        payload: error
    }
}

export const fetchPosts = () => {
    return {
        type: ActionTypes.FETCH_POSTS
    }
}

export const fetchPostsSuccess = (posts) => {
    return {
        type: ActionTypes.FETCH_POSTS_SUCCESS,
        payload: posts
    }
}

export const fetchPostsError = () => {
    return {
        type: ActionTypes.FETCH_POSTS_ERROR
    }
}

export const editPost = (query) => {
    return {
        type: ActionTypes.EDIT_POST,
        payload: query
    }
}

export const editPostSuccess = (post) =>{
    return {
        type: ActionTypes.EDIT_POST_SUCCESS,
        payload: post
    }
}

export const editPostError = () => {
  return {
    type: ActionTypes.EDIT_POST_ERROR,
  }
}

export const updatePost = (post) => {
    console.log(post);
    return {
        type: ActionTypes.UPDATE_POST,
        payload: post
    }
}

export const updatePostSuccess = (posts) => {
    return {
        type: ActionTypes.UPDATE_POST_SUCCESS,
        payload: posts
    }
}

export const updatePostError = (message) => {
    return {
        type: ActionTypes.UPDATE_POST_ERROR,
        payload: message
    }
}

export const deletePost = (post) => {
    console.log(post);
    return {
        type: ActionTypes.DELETE_POST,
        payload: post
    }
}

export const deletePostSuccess = (posts) => {
    return {
        type: ActionTypes.DELETE_POST_SUCCESS,
        payload: posts
    }
}

export const deletePostError = (message) => {
    return {
        type: ActionTypes.DELETE_POST_ERROR,
        payload: message
    }
}