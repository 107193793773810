import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Products = ({match}) => (
  <Switch>
    <Route path={`${match.url}/pass`} component={asyncComponent(() => import('../../components/Products/Pass'))}/>
    <Route path={`${match.url}/merchandise`} component={asyncComponent(() => import('../../components/Products/Merchandise'))}/>
  </Switch>
);

export default Products;
