import {
  OPEN_WORKSHOP_MODAL, OPEN_COMPETITION_MODAL, FETCH_WORKSHOPS, FETCH_WORKSHOPS_SUCCESS, ADD_WORKSHOP, ADD_WORKSHOP_SUCCESS, ADD_WORKSHOP_ERROR, UPDATE_WORKSHOP, UPDATE_WORKSHOP_SUCCESS, UPDATE_WORKSHOP_ERROR, DELETE_WORKSHOP, DELETE_WORKSHOP_SUCCESS, DELETE_WORKSHOP_ERROR, RESET_WORKSHOP, EDIT_WORKSHOP_SUCCESS, EDIT_WORKSHOP_ERROR,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';
import { imageServerURL } from '../../util/common';

const default_workshop = {
  _id: null,
  parent_sku: null,
  name: null,
  group: null,
  classes: null,
  gender: null,
  category: null,
  type: null,
  unit_price: null,
  min_qty: null,
  usage_limit: null,
  duration: null,
  event: null,
  special: null,
  premise: [],
  location: [],
  authority: null,
  desc: null,
  img: null,
  enable: false
}

const INIT_STATE = {
  loading: false,
  edit: false,
  workshop_modal_open: false,
  competition_modal_open: false,
  workshop: default_workshop,
  workshop_list: [],
  active_workshops: []
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    // Workshops
    case RESET_WORKSHOP:
      return { ...state, loading: false, workshop: { ...default_workshop }, edit: false, workshop_modal_open: false, competition_modal_open: false }

    case OPEN_WORKSHOP_MODAL:
      return { ...state, workshop_modal_open: payload }

    case OPEN_COMPETITION_MODAL:
      return { ...state, competition_modal_open: payload }

    case FETCH_WORKSHOPS:
      return { ...state, loading: true }

    case EDIT_WORKSHOP_SUCCESS:
      console.log(payload);
      item = state.workshop_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          workshop: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          loading: false,
          edit: true,
          workshop_modal_open: payload.type.toLowerCase() === "workshop" ? true : false,
          competition_modal_open: payload.type.toLowerCase() === "competition" ? true : false
        }
      } else {
        return {
          ...state,
          workshop_list: state.workshop_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          workshop: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          loading: false,
          edit: true,
          workshop_modal_open: payload.type.toLowerCase() === "workshop" ? true : false,
          competition_modal_open: payload.type.toLowerCase() === "competition" ? true : false
        }
      }

    case EDIT_WORKSHOP_ERROR:
      message.error(payload);
      return { ...state, loading: false }

    case ADD_WORKSHOP:
      console.log(payload);
      return { ...state, loading: true }

    case ADD_WORKSHOP_SUCCESS:
      message.success('New workshop added successfully');
      return { ...state, workshop_list: payload.map(el => ({ key: el._id, ...el })), workshop: { ...default_workshop }, loading: false, workshop_modal_open: false, competition_modal_open: false, edit: false }

    case ADD_WORKSHOP_ERROR:
      message.error(payload);
      return { ...state, loading: false }

    case UPDATE_WORKSHOP:
      console.log(payload);
      return { ...state, loading: true }

    case UPDATE_WORKSHOP_SUCCESS:
      message.success('Workshop updated successfully');
      return { ...state, workshop_list: payload.map(el => ({ key: el._id, ...el })), workshop: { ...default_workshop }, loading: false, workshop_modal_open: false, competition_modal_open: false, edit: false }

    case UPDATE_WORKSHOP_ERROR:
      message.error(payload);
      return { ...state, loading: false }

    case DELETE_WORKSHOP:
      console.log(payload);
      return { ...state, loading: true }

    case DELETE_WORKSHOP_SUCCESS:
      message.success('Workshop deleted successfully');
      return { ...state, workshop_list: payload.map(el => ({ key: el._id, ...el })), workshop: { ...default_workshop }, loading: false, workshop_modal_open: false, competition_modal_open: false, edit: false }

    case DELETE_WORKSHOP_ERROR:
      message.error(payload);
      return { ...state, loading: false }

    case FETCH_WORKSHOPS_SUCCESS:
      let workshop_list = [];
      let active_workshops = [];

      payload.forEach(el => {
        workshop_list.push({ key: el._id, ...el });

        if (el.enable) {
          active_workshops.push({ key: el._id, ...el });
        }
      })

      return { ...state, workshop_list, active_workshops, loading: false }

    default:
      return state;
  }
}
