import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Bookings = ({ match }) => (
    <Switch>
        <Route path={`${match.url}`} component={asyncComponent(() => import('../../components/Bookings/BookingCalendar'))} />
    </Switch>
)

export default Bookings;
