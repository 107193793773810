import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Products = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={asyncComponent(() => import('../../components/Gallery/PhotoGallery'))}/>
  </Switch>
);

export default Products;
