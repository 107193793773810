import {
  OPEN_PREMISE_MODAL,
  RESET_PREMISE,
  ADD_PREMISE,
  ADD_PREMISE_SUCCESS,
  ADD_PREMISE_ERROR,
  EDIT_PREMISE_SUCCESS,
  EDIT_PREMISE_ERROR,
  UPDATE_PREMISE,
  UPDATE_PREMISE_SUCCESS,
  UPDATE_PREMISE_ERROR,
  DELETE_PREMISE,
  DELETE_PREMISE_SUCCESS,
  DELETE_PREMISE_ERROR,
  FETCH_PREMISES,
  FETCH_PREMISES_SUCCESS,
  FETCH_PREMISES_ERROR,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  FETCH_COUNTRIES_SUCCESS,
  GET_OSS_TOKEN_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';
import { imageServerURL } from '../../util/common';
import config from '../../config.json';

const OSS = require('ali-oss');

const default_premise = {
  _id: null,
  email: null,
  phone: null,
  contacts: [],
  locations: [],
  img: [],
  note: null,
  enable: false
};

const INIT_STATE = {
  pending: false,
  edit: false,
  premise_modal_open: false,
  premise: default_premise,
  premise_list: [],
  countries: [],
  oss_cred: null,
  OSSclient: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case RESET_PREMISE:
      return { ...state, pending: false, premise: { ...default_premise }, edit: false, premise_modal_open: false }

    case OPEN_PREMISE_MODAL:
      return { ...state, premise_modal_open: payload }

    case FETCH_PREMISES:
    case ADD_PREMISE:
    case UPDATE_LOCATION:
      return { ...state, pending: true }

    case ADD_PREMISE_SUCCESS:
      message.success('New premise added successfully');
      return { ...state, premise_list: payload.map(el => ({ key: el._id, ...el })), pending: false, premise_modal_open: false }

    case FETCH_PREMISES_ERROR:
    case ADD_PREMISE_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_PREMISES_SUCCESS:
      return { ...state, premise_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case EDIT_PREMISE_SUCCESS:
      console.log(payload);
      item = state.premise_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          premise: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          premise_modal_open: true
        }
      } else {
        return {
          ...state,
          premise_list: state.premise_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          premise: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          premise_modal_open: true
        }
      }

    case UPDATE_PREMISE:
      console.log('Update premise Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_PREMISE_SUCCESS:
      message.success('Premise updated successfully');
      return { ...state, premise_list: payload.map(el => ({ key: el._id, ...el })), premise: { ...default_premise }, pending: false, premise_modal_open: false, edit: false }

    case DELETE_PREMISE:
      console.log('Delete premise Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_PREMISE_SUCCESS:
      message.success('Premise deleted successfully');
      return { ...state, premise_list: payload.map(el => ({ key: el._id, ...el })), premise: { ...default_premise }, pending: false, premise_modal_open: false, edit: false }

    case UPDATE_LOCATION_SUCCESS:
      console.log(payload)
      message.success('Location updated successfully');
      return { ...state, premise: { ...state.premise, locations: payload }, pending: false }

    case DELETE_LOCATION_SUCCESS:
      message.success('Location deleted successfully');
      return { ...state, premise: { ...state.premise, locations: payload }, pending: false }

    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: payload, pending: false }
  
    case EDIT_PREMISE_ERROR:
    case UPDATE_PREMISE_ERROR:
    case DELETE_PREMISE_ERROR:
    case UPDATE_LOCATION_ERROR:
    case DELETE_LOCATION_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case GET_OSS_TOKEN_SUCCESS:
      let client = new OSS({
        region: config.oss.region,
        bucket: config.oss.bucket,
        accessKeyId: payload.AccessKeyId,
        accessKeySecret: payload.AccessKeySecret,
        stsToken: payload.SecurityToken
      });
      return { ...state, oss_cred: payload, OSSclient: client };

    default:
      return state;
  }
}
