import {
  OPEN_BANNER_MODAL,
  RESET_BANNER,
  ADD_BANNER,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_ERROR,
  EDIT_BANNER_SUCCESS,
  EDIT_BANNER_ERROR,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_ERROR,
  DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_ERROR,
  FETCH_BANNERS,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNERS_ERROR,
  UPDATE_BANNER_ORDERS,
  UPDATE_BANNER_ORDERS_SUCCESS,
  UPDATE_BANNER_ORDERS_ERROR,
  GET_OSS_TOKEN_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { imageServerURL } from '../../util/common';
import config from '../../config.json';

const OSS = require('ali-oss');

const default_banner = {
  _id: null,
  img: null,
  start_date: null,
  end_date: null,
  url: null,
  title: null,
  subtitle: null
};

const INIT_STATE = {
  pending: false,
  edit: false,
  banner_modal_open: false,
  banner: default_banner,
  banner_list: [],
  oss_cred: null,
  OSSclient: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case RESET_BANNER:
      return { ...state, pending: false, banner: { ...default_banner }, edit: false, banner_modal_open: false }

    case OPEN_BANNER_MODAL:
      return { ...state, banner_modal_open: payload }

    // case FETCH_BANNERS:
    case ADD_BANNER:
      return { ...state, pending: true }

    case ADD_BANNER_SUCCESS:
      message.success('New banner added successfully');
      return { ...state, banner_list: payload.map(el => ({ key: el._id, ...el })), pending: false, banner_modal_open: false }

    case ADD_BANNER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_BANNERS_SUCCESS:
      return { ...state, banner_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case EDIT_BANNER_SUCCESS:
      console.log(payload);
      item = state.banner_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          banner: { ...payload, start_date: payload.start_date ? moment(payload.start_date) : null, end_date: payload.end_date ? moment(payload.end_date) : null },
          pending: false,
          edit: true,
          banner_modal_open: true
        }
      } else {
        return {
          ...state,
          banner_list: state.banner_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          banner: { ...payload, start_date: payload.start_date ? moment(payload.start_date) : null, end_date: payload.end_date ? moment(payload.end_date) : null },
          pending: false,
          edit: true,
          banner_modal_open: true
        }
      }

    case EDIT_BANNER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_BANNER:
      console.log('Update banner Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_BANNER_SUCCESS:
      message.success('Banner updated successfully');
      return { ...state, banner_list: payload.map(el => ({ key: el._id, ...el })), banner: { ...default_banner }, pending: false, banner_modal_open: false, edit: false }

    case UPDATE_BANNER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_BANNER:
      return { ...state, pending: true }

    case DELETE_BANNER_SUCCESS:
      message.success('Banner deleted successfully');
      return { ...state, banner_list: payload.map(el => ({ key: el._id, ...el })), banner: { ...default_banner }, pending: false, banner_modal_open: false, edit: false }

    case DELETE_BANNER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    // case UPDATE_BANNER_ORDERS:
    //   return { ...state, pending: true }

    case UPDATE_BANNER_ORDERS_SUCCESS:
      message.success('Banner orders updated successfully');
      return { ...state, pending: false }

    case UPDATE_BANNER_ORDERS_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case GET_OSS_TOKEN_SUCCESS:
      let client = new OSS({
        region: config.oss.region,
        bucket: config.oss.bucket,
        accessKeyId: payload.AccessKeyId,
        accessKeySecret: payload.AccessKeySecret,
        stsToken: payload.SecurityToken
      });
      return { ...state, oss_cred: payload, OSSclient: client };

    default:
      return state;
  }
}
