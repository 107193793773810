import * as ActionTypes from '../../constants/ActionTypes';

export const fetchPremises = () => {
  return {
    type: ActionTypes.FETCH_PREMISES
  }
}

export const fetchPremisesSuccess = (premises) => {
  return {
    type: ActionTypes.FETCH_PREMISES_SUCCESS,
    payload: premises
  }
}

export const fetchPremisesError = (error) => {
  return {
    type: ActionTypes.FETCH_PREMISES_ERROR,
    payload: error
  }
}

export const openPremiseModal = (state) => {
  return {
    type: ActionTypes.OPEN_PREMISE_MODAL,
    payload: state
  }
}

export const resetPremise = () => {
  console.log('Reset Premise');
  return {
    type: ActionTypes.RESET_PREMISE
  }
}

export const addPremise = (premise) => {
  return {
    type: ActionTypes.ADD_PREMISE,
    premise
  }
}

export const addPremiseSuccess = (premises) => {
  return {
    type: ActionTypes.ADD_PREMISE_SUCCESS,
    payload: premises
  }
}

export const addPremiseError = (error) => {
  return {
    type: ActionTypes.ADD_PREMISE_ERROR,
    payload: error
  }
}

export const editPremise = (query) => {
  return {
    type: ActionTypes.EDIT_PREMISE,
    payload: query
  }
}

export const editPremiseSuccess = (premise) => {
  return {
    type: ActionTypes.EDIT_PREMISE_SUCCESS,
    payload: premise
  }
}

export const editPremiseError = (error) => {
  return {
    type: ActionTypes.EDIT_PREMISE_ERROR,
    payload: error
  }
}

export const updatePremise = (premise) => {
  console.log(premise);
  return {
    type: ActionTypes.UPDATE_PREMISE,
    payload: premise
  }
}

export const updatePremiseSuccess = (premises) => {
  return {
    type: ActionTypes.UPDATE_PREMISE_SUCCESS,
    payload: premises
  }
}

export const updatePremiseError = (error) => {
  return {
    type: ActionTypes.UPDATE_PREMISE_ERROR,
    payload: error
  }
}

export const deletePremise = (premise) => {
  console.log(premise);
  return {
    type: ActionTypes.DELETE_PREMISE,
    payload: premise
  }
}

export const deletePremiseSuccess = (premises) => {
  return {
    type: ActionTypes.DELETE_PREMISE_SUCCESS,
    payload: premises
  }
}

export const deletePremiseError = (error) => {
  return {
    type: ActionTypes.DELETE_PREMISE_ERROR,
    payload: error
  }
}

export const fetchLocations = () => {
  return {
    type: ActionTypes.FETCH_LOCATIONS
  }
}

export const fetchLocationsSuccess = (locations) => {
  return {
    type: ActionTypes.FETCH_LOCATIONS_SUCCESS,
    payload: locations
  }
}

export const fetchLocationsError = (error) => {
  return {
    type: ActionTypes.FETCH_LOCATIONS_ERROR,
    payload: error
  }
}

export const updateLocation = (location) => {
  return {
    type: ActionTypes.UPDATE_LOCATION,
    payload: location
  }
}

export const updateLocationSuccess = (locations) => {
  return {
    type: ActionTypes.UPDATE_LOCATION_SUCCESS,
    payload: locations
  }
}

export const updateLocationError = (error) => {
  return {
    type: ActionTypes.UPDATE_LOCATION_ERROR,
    payload: error
  }
}

export const deleteLocation = (location) => {
  return {
    type: ActionTypes.DELETE_LOCATION,
    payload: location
  }
}

export const deleteLocationSuccess = (locations) => {
  return {
    type: ActionTypes.DELETE_LOCATION_SUCCESS,
    payload: locations
  }
}

export const deleteLocationError = (error) => {
  return {
    type: ActionTypes.DELETE_LOCATION_ERROR,
    payload: error
  }
}

export const fetchCountries = () => {
  return {
    type: ActionTypes.FETCH_COUNTRIES,
  }
}

export const fetchCountriesSuccess = (countries) => {
  return {
    type: ActionTypes.FETCH_COUNTRIES_SUCCESS,
    payload: countries
  }
}

export const requestOSS = () => {
  return {
    type: ActionTypes.GET_OSS_TOKEN
  }
}

export const getOSStokenSuccess = (cred) => {
  return {
    type: ActionTypes.GET_OSS_TOKEN_SUCCESS,
    payload: cred
  }
}
