import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchPremisesSuccess, addPremiseSuccess, addPremiseError, editPremiseSuccess, editPremiseError, updatePremiseSuccess, updatePremiseError, deletePremiseSuccess, deletePremiseError, updateLocationSuccess, updateLocationError, deleteLocationSuccess, deleteLocationError, fetchLocationsSuccess, fetchLocationsError, fetchCountriesSuccess, getOSStokenSuccess } from '../actions/Premises';

function* addPremiseRequest({ premise }) {
  console.log('Add Premise called');
  try {
    const resp = yield call(axios.post, '/v1/admin/add_premise', premise);
    yield put(addPremiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addPremiseError(error.response));
  }
}

function* editPremiseRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_premise', payload);
    yield put(editPremiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editPremiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updatePremiseRequest({ payload }) {
  console.log('Update Premise called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_premise', payload);
    console.log(resp);
    yield put(updatePremiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updatePremiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deletePremiseRequest({ payload }) {
  console.log('Delete Premise called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_premise', payload);
    console.log(resp);
    yield put(deletePremiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deletePremiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchPremisesRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/premises');
    yield put(fetchPremisesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

function* updateLocationRequest({ payload }) {
  console.log('Update Location called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_location', payload);
    console.log(resp);
    yield put(updateLocationSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateLocationError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteLocationRequest({ payload }) {
  console.log('Delete Location called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_location', payload);
    console.log(resp);
    yield put(deleteLocationSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteLocationError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchLocationsRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/locations');
    yield put(fetchLocationsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

function* fetchCountriesRequest() {
  try {
    const resp = yield call(axios.get, '/v1/countries');
    console.log(resp.data);
    yield put(fetchCountriesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

// function* getOSStokenRequest() {
//   try {
//     const resp = yield call(axios.post, '/v1/admin/request_oss_access_token');
//     console.log(resp);
//     yield put(getOSStokenSuccess(resp.data));
//   } catch (error) {
//     console.log(error.response);
//     yield put(fetchError(error.response));
//   }
// }

// export function* getOSStoken() {
//   yield takeLatest(ActionTypes.GET_OSS_TOKEN, getOSStokenRequest);
// }

export function* fetchPremises() {
  yield takeLatest(ActionTypes.FETCH_PREMISES, fetchPremisesRequest);
}

export function* addPremise() {
  yield takeLatest(ActionTypes.ADD_PREMISE, addPremiseRequest);
}

export function* editPremise() {
  yield takeLatest(ActionTypes.EDIT_PREMISE, editPremiseRequest);
}

export function* updatePremise() {
  yield takeLatest(ActionTypes.UPDATE_PREMISE, updatePremiseRequest);
}

export function* deletePremise() {
  yield takeLatest(ActionTypes.DELETE_PREMISE, deletePremiseRequest);
}

export function* fetchLocations() {
  yield takeLatest(ActionTypes.FETCH_LOCATIONS, fetchLocationsRequest);
}

export function* updateLocation() {
  yield takeLatest(ActionTypes.UPDATE_LOCATION, updateLocationRequest);
}

export function* deleteLocation() {
  yield takeLatest(ActionTypes.DELETE_LOCATION, deleteLocationRequest);
}

export function* fetchCountries() {
  yield takeLatest(ActionTypes.FETCH_COUNTRIES, fetchCountriesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPremises),
    fork(addPremise),
    fork(editPremise),
    fork(updatePremise),
    fork(deletePremise),
    fork(fetchLocations),
    fork(updateLocation),
    fork(deleteLocation),
    fork(fetchCountries),
    // fork(getOSStoken)
  ]);
}
