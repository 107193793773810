import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchUsersSuccess, addUserSuccess, addUserError, editUserSuccess, editUserError, updateUserSuccess, updateUserError, deleteUserSuccess, deleteUserError, getOSStokenSuccess } from '../actions/Users';

function* addUserRequest({ user }) {
  console.log('Add User called');
  try {
    const resp = yield call(axios.post, '/v1/admin/add_user', user);
    yield put(addUserSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addUserError(error.response));
  }
}

function* editUserRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_user', payload);
    yield put(editUserSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editUserError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateUserRequest({ payload }) {
  console.log('Update User called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_user', payload);
    console.log(resp);
    yield put(updateUserSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateUserError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteUserRequest({ payload }) {
  console.log('Delete User called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_user', payload);
    console.log(resp);
    yield put(deleteUserSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteUserError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchUsersRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/users');
    yield put(fetchUsersSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

function* getOSStokenRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/request_oss_access_token');
    console.log(resp);
    yield put(getOSStokenSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

export function* getOSStoken() {
  yield takeLatest(ActionTypes.GET_OSS_TOKEN, getOSStokenRequest);
}

export function* fetchUsers() {
  yield takeLatest(ActionTypes.FETCH_USERS, fetchUsersRequest);
}

export function* addUser() {
  yield takeLatest(ActionTypes.ADD_USER, addUserRequest);
}

export function* editUser() {
  yield takeLatest(ActionTypes.EDIT_USER, editUserRequest);
}

export function* updateUser() {
  yield takeLatest(ActionTypes.UPDATE_USER, updateUserRequest);
}

export function* deleteUser() {
  yield takeLatest(ActionTypes.DELETE_USER, deleteUserRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchUsers),
    fork(addUser),
    fork(editUser),
    fork(updateUser),
    fork(deleteUser),
    fork(getOSStoken)
  ]);
}

