import { message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import {
  FETCH_ATTENDANCE,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_ERROR,
  UPDATE_ATTENDANCE,
  UPDATE_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_ERROR,
  EXPORT_ATTENDANCE,
  EXPORT_ATTENDANCE_SUCCESS,
  EXPORT_ATTENDANCE_ERROR,
  EDIT_ATTENDANCE,
  EDIT_ATTENDANCE_SUCCESS,
  EDIT_ATTENDANCE_ERROR,
  RESET_ATTENDANCE,
  OPEN_ATTENDANCE_MODAL,
} from '../../constants/ActionTypes';

const default_attendance = {
  _id: null,
  full_name: null,
  email: null,
  location: null,
  signed_in: null,
  signed_out: null,
  remarks: '',
  add_remark: '',
  activity: '',
  activities: []
}

const INIT_STATE = {
  pending: false,
  edit: false,
  attendance_count: 0,
  attendance_modal_open: false,
  attendance: default_attendance,
  attendance_list: []
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_ATTENDANCE:
    case UPDATE_ATTENDANCE:
    case EXPORT_ATTENDANCE:
    case EDIT_ATTENDANCE:
      return { ...state, pending: true }

    case FETCH_ATTENDANCE_SUCCESS:
      return { ...state, pending: false, attendance_list: payload.records, attendance_count: payload.count }

    case FETCH_ATTENDANCE_ERROR:
    case UPDATE_ATTENDANCE_ERROR:
    case EXPORT_ATTENDANCE_ERROR:
    case EDIT_ATTENDANCE_ERROR:
      return { ...state, pending: false }

    case UPDATE_ATTENDANCE_SUCCESS:
      return { ...state, pending: false, attendance_list: payload.records, attendance_count: payload.count, attendance: { ...default_attendance }, attendance_modal_open: false, edit: false }

    case EXPORT_ATTENDANCE_SUCCESS:
      message.success('Attendance exported successfully');
      return { ...state, pending: false }

    case RESET_ATTENDANCE:
      return { ...state, pending: false, attendance: { ...default_attendance }, edit: false, attendance_modal_open: false }

    case OPEN_ATTENDANCE_MODAL:
      return { ...state, attendance_modal_open: payload }

    case EDIT_ATTENDANCE_SUCCESS:
      console.log(payload);
      let item;
      item = state.attendance_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          attendance: {
            ...payload,
            full_name: `${payload.name.title} ${payload.name.last} ${payload.name.first}`,
            signed_in: moment(payload.signed_in),
            signed_out: payload.signed_out ? moment(payload.signed_out) : null,
            remarks: payload.remarks.map((remark, i) => `${i + 1}. ${remark}`).join('\n')
          },
          pending: false,
          edit: true,
          attendance_modal_open: true
        };
      } else {
        return {
          ...state,
          attendance_list: state.attendance_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          attendance: {
            ...payload,
            full_name: `${payload.name.title} ${payload.name.last} ${payload.name.first}`,
            signed_in: moment(payload.signed_in),
            signed_out: payload.signed_out ? moment(payload.signed_out) : null,
            remarks: payload.remarks.map((remark, i) => `${i + 1}. ${remark}`).join('\n')
          },
          pending: false,
          edit: true,
          attendance_modal_open: true
        };
      }

    default:
      return state;
  }
}
