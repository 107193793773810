import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchSalesSuccess, fetchSalesReportSuccess } from '../actions/Sales';


function* fetchSalesRequest({ payload }) {
  console.log(payload);
  let query = {}

  if (payload) {
    query = payload;
  }

  try {
    const resp = yield call(axios.post, '/v1/admin/fetch_sales_summary', query);
    yield put(fetchSalesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchSalesReportRequest({ payload }) {
  console.log(payload);
  let query = {}

  if (payload) {
    query = payload;
  }

  try {
    const resp = yield call(axios.post, '/v1/admin/get_sales_report', query);
    yield put(fetchSalesReportSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchSales() {
  yield takeLatest(ActionTypes.FETCH_SALES, fetchSalesRequest);
}

export function* fetchSalesReport() {
  yield takeLatest(ActionTypes.FETCH_SALES_REPORT, fetchSalesReportRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchSales),
    fork(fetchSalesReport)
  ]);
}
