import {
  OPEN_MERCHANDISE_MODAL, FETCH_MERCHANDISES, FETCH_MERCHANDISES_SUCCESS, ADD_MERCHANDISE, ADD_MERCHANDISE_SUCCESS, ADD_MERCHANDISE_ERROR, UPDATE_MERCHANDISE, UPDATE_MERCHANDISE_SUCCESS, UPDATE_MERCHANDISE_ERROR, DELETE_MERCHANDISE, DELETE_MERCHANDISE_SUCCESS, DELETE_MERCHANDISE_ERROR, RESET_MERCHANDISE, EDIT_MERCHANDISE_SUCCESS, EDIT_MERCHANDISE_ERROR, GET_MERCHANDISE_SETUP_SUCCESS, IMPORT_MERCHANDISES, IMPORT_MERCHANDISES_SUCCESS, IMPORT_MERCHANDISES_ERROR, IMPORT_INVENTORY, IMPORT_INVENTORY_SUCCESS, FETCH_INVENTORY_IMPORTS, FETCH_INVENTORY_IMPORTS_SUCCESS, FETCH_INVENTORY_IMPORTS_ERROR,
  OPEN_CATEGORY_MODAL, FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, ADD_CATEGORY, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_ERROR, UPDATE_CATEGORY, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR, DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR, RESET_CATEGORY, EDIT_CATEGORY_SUCCESS, EDIT_CATEGORY_ERROR,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { imageServerURL } from '../../util/common';

const default_merchandise = {
  _id: null,
  parent_sku: null,
  name: null,
  category: [],
  type: [],
  size: null,
  desc: null,
  unit_price: null,
  usage_limit: 0,
  duration: 0,
  img: null,
  stock_date: moment(),
  enable: false
}

const default_category = {
  _id: null,
  enable: false,
  desc: null,
  img: null,
  enable: false
}

const INIT_STATE = {
  pending: false,
  edit: false,
  setup: {
    categories: [],
    types: []
  },
  category_modal_open: false,
  merchandise_modal_open: false,
  merchandise: default_merchandise,
  category: default_category,
  merchandise_list: [],
  category_list: [],
  inventory_files: []
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {

    // Merchandises
    case RESET_MERCHANDISE:
      return { ...state, pending: false, merchandise: { ...default_merchandise }, edit: false, merchandise_modal_open: false }

    case OPEN_MERCHANDISE_MODAL:
      return { ...state, merchandise_modal_open: payload }

    case FETCH_MERCHANDISES:
      return { ...state, pending: true }

    case IMPORT_MERCHANDISES:
      console.log('Import Merchandises');
      return { ...state, pending: true }

    case EDIT_MERCHANDISE_SUCCESS:
      console.log(payload);
      item = state.merchandise_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          merchandise: { ...payload, stock_date: moment(payload.stock_date), img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          merchandise_modal_open: true
        }
      } else {
        return {
          ...state,
          merchandise_list: state.merchandise_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          merchandise: { ...payload, stock_date: moment(payload.stock_date), img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          merchandise_modal_open: true
        }
      }

    case EDIT_MERCHANDISE_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case ADD_MERCHANDISE:
      console.log('Add merchandise Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case ADD_MERCHANDISE_SUCCESS:
      message.success('New merchandise added successfully');
      return { ...state, merchandise_list: payload.map(el => ({ key: el._id, ...el })), merchandise: { ...default_merchandise }, pending: false, merchandise_modal_open: false, edit: false }

    case ADD_MERCHANDISE_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_MERCHANDISE:
      console.log('Update merchandise Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_MERCHANDISE_SUCCESS:
      message.success('Merchandise updated successfully');
      return { ...state, merchandise_list: payload.map(el => ({ key: el._id, ...el })), merchandise: { ...default_merchandise }, pending: false, merchandise_modal_open: false, edit: false }

    case UPDATE_MERCHANDISE_ERROR:
    case IMPORT_MERCHANDISES_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_MERCHANDISE:
      console.log('Delete merchandise Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_MERCHANDISE_SUCCESS:
      message.success('Merchandise deleted successfully');
      return { ...state, merchandise_list: payload.map(el => ({ key: el._id, ...el })), merchandise: { ...default_merchandise }, pending: false, merchandise_modal_open: false, edit: false }

    case DELETE_MERCHANDISE_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_MERCHANDISES_SUCCESS:
      return { ...state, merchandise_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case IMPORT_MERCHANDISES_SUCCESS:
      message.success('Merchandises imported successfully');
      return { ...state, merchandise_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case GET_MERCHANDISE_SETUP_SUCCESS:
      console.log(payload);
      return {
        ...state,
        setup: payload
      }

    // Categories
    case RESET_CATEGORY:
      return { ...state, pending: false, category: { ...default_category }, edit: false, category_modal_open: false }

    case OPEN_CATEGORY_MODAL:
      return { ...state, category_modal_open: payload }

    case FETCH_CATEGORIES:
      return { ...state, pending: true }

    case EDIT_CATEGORY_SUCCESS:
      console.log(payload);
      item = state.category_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          category: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          category_modal_open: true
        }
      } else {
        return {
          ...state,
          category_list: state.category_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          category: { ...payload, img: payload.img.map((el, i) => ({ uid: i, name: el, status: 'done', url: imageServerURL + el, response: imageServerURL + el })) },
          pending: false,
          edit: true,
          category_modal_open: true
        }
      }

    case EDIT_CATEGORY_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case ADD_CATEGORY:
      console.log('Add category Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case ADD_CATEGORY_SUCCESS:
      message.success('New category added successfully');
      return { ...state, category_list: payload.map(el => ({ key: el._id, ...el })), category: { ...default_category }, pending: false, category_modal_open: false, edit: false }

    case ADD_CATEGORY_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_CATEGORY:
      console.log('Update category Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_CATEGORY_SUCCESS:
      message.success('Category updated successfully');
      return { ...state, category_list: payload.map(el => ({ key: el._id, ...el })), category: { ...default_category }, pending: false, category_modal_open: false, edit: false }

    case UPDATE_CATEGORY_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_CATEGORY:
      console.log('Delete category Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_CATEGORY_SUCCESS:
      message.success('Category deleted successfully');
      return { ...state, category_list: payload.map(el => ({ key: el._id, ...el })), category: { ...default_category }, pending: false, category_modal_open: false, edit: false }

    case DELETE_CATEGORY_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_CATEGORIES_SUCCESS:
      return { ...state, category_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case IMPORT_INVENTORY:
    case FETCH_INVENTORY_IMPORTS:
      return { ...state, pending: true }

    case IMPORT_INVENTORY_SUCCESS:
    case FETCH_INVENTORY_IMPORTS_SUCCESS:
      return { ...state, inventory_files: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case FETCH_INVENTORY_IMPORTS_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    default:
      return state;
  }
}