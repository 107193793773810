import React from "react";
import { Form, Button, Input, message } from "antd";

const ChangePasswordForm = ({ onSubmit, onCancel }) => {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    const { newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match!");
      return;
    }

    if (newPassword.trim() === "") {
      message.error("Password cannot be empty!");
      return;
    }

    onSubmit(newPassword);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          { required: true, message: "Please enter your new password!" },
          { min: 6, message: "Password must be at least 6 characters long!" },
        ]}
      >
        <Input.Password placeholder="Enter new password" />
      </Form.Item>
      <Form.Item
        label="Confirm New Password"
        name="confirmPassword"
        rules={[
          { required: true, message: "Please confirm your new password!" },
          { min: 6, message: "Password must be at least 6 characters long!" },
        ]}
      >
        <Input.Password placeholder="Confirm new password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Update Password
        </Button>
        {/* <Button
          type="default"
          htmlType="button"
          block
          style={{ marginTop: 8 }}
          onClick={() => {
            form.resetFields();
            onCancel && onCancel();
          }}
        >
          Cancel
        </Button> */}
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;
