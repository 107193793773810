import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover, Modal} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { changePassword } from "../../appRedux/actions/Auth";
import ChangePasswordForm from "../ChangePassword/ChangePasswordForm";

const UserInfo = () => {

  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangePassword = (newPassword) => {
    dispatch(changePassword(newPassword));
    setIsModalVisible(false);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={showModal}>Change Password</li>
      {/* <li>Connections</li> */}
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <>
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">
        <Avatar src={"https://via.placeholder.com/150"}
          className="gx-avatar gx-pointer" alt="" />
      </Popover>
      <Modal
        title="Change Password"
        visible={isModalVisible}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
      >
        <ChangePasswordForm
          onSubmit={handleChangePassword}
          onCancel={handleCancel} />
      </Modal>
    </>
  )

}

export default UserInfo;
