import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Modal, Popover} from "antd";
import {userSignOut, changePassword} from "appRedux/actions/Auth";
import ChangePasswordForm from "../../components/ChangePassword/ChangePasswordForm";

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.userProfile);
  const [isModalVisible, setIsModalVisible] = useState(false);
  console.log(user);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangePassword = (newPassword) => {
    dispatch(changePassword(newPassword));
    setIsModalVisible(false);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={showModal}>Change Password</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <>
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={"https://via.placeholder.com/150"}
            className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          <span className="gx-avatar-name">{user && user.name.first}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>
      <Modal
        title="Change Password"
        visible={isModalVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
      >
        <ChangePasswordForm
          onSubmit={handleChangePassword}
          onCancel={handleCancel}
        />
      </Modal>
    </>
  )
};

export default UserProfile;
