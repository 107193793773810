import {
  OPEN_MEMBER_MODAL,
  RESET_MEMBER,
  ADD_MEMBER,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_ERROR,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_ERROR,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_ERROR,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  FETCH_MEMBERS,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_ERROR,
  OPEN_MEMBER_PASSES_MODAL,
  RESET_MEMBER_PASSES,
  EDIT_MEMBER_PASSES_SUCCESS,
  EDIT_MEMBER_PASSES_ERROR,
  UPDATE_MEMBER_TICKET,
  UPDATE_MEMBER_TICKET_SUCCESS,
  UPDATE_MEMBER_TICKET_ERROR,
  GET_OSS_TOKEN_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import config from '../../config.json';

const OSS = require('ali-oss');

const default_member = {
  _id: null,
  name: null,
  phone: null,
  address: null,
  dob: null,
  activated: null,
  expired: null,
  affiliation: null,
  tier: null,
  credit: 0,
  reward_point: 0,
  note: null,
  enable: false
};

const INIT_STATE = {
  pending: false,
  edit: false,
  member_modal_open: false,
  member: default_member,
  member_list: [],
  passes_modal_open: false,
  pass_list: [],
  oss_cred: null,
  OSSclient: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case RESET_MEMBER:
      return { ...state, pending: false, member: { ...default_member }, edit: false, member_modal_open: false }

    case OPEN_MEMBER_MODAL:
      return { ...state, member_modal_open: payload }

    case FETCH_MEMBERS:
    case ADD_MEMBER:
      return { ...state, pending: true }

    case ADD_MEMBER_SUCCESS:
      message.success('New member added successfully');
      return { ...state, member_list: payload.map(el => ({ key: el._id, ...el })), pending: false, member_modal_open: false }

    case ADD_MEMBER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_MEMBERS_SUCCESS:
      return { ...state, member_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case EDIT_MEMBER_SUCCESS:
      console.log(payload);
      item = state.member_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          member: { ...payload, dob: moment(payload.dob), activated: moment(payload.activated), expired: moment(payload.expired) },
          pending: false,
          edit: true,
          member_modal_open: true
        }
      } else {
        return {
          ...state,
          member_list: state.member_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          member: { ...payload, dob: moment(payload.dob), activated: moment(payload.activated), expired: moment(payload.expired) },
          pending: false,
          edit: true,
          member_modal_open: true
        }
      }
      return { ...state }

    case EDIT_MEMBER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_MEMBER:
      console.log('Update member Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_MEMBER_SUCCESS:
      message.success('Member updated successfully');
      return { ...state, member_list: payload.map(el => ({ key: el._id, ...el })), member: { ...default_member }, pending: false, member_modal_open: false, edit: false }

    case UPDATE_MEMBER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_MEMBER:
      console.log('Delete member Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_MEMBER_SUCCESS:
      message.success('Member deleted successfully');
      return { ...state, member_list: payload.map(el => ({ key: el._id, ...el })), member: { ...default_member }, pending: false, member_modal_open: false, edit: false }

    case DELETE_MEMBER_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case RESET_MEMBER_PASSES:
      return { ...state, pass_list: [] }

    case OPEN_MEMBER_PASSES_MODAL:
      return { ...state, passes_modal_open: payload }

    case EDIT_MEMBER_PASSES_SUCCESS:
      return {
        ...state,
        pass_list: payload.map(el => {
          el.ticket.status = (el.ticket.status === 'Created' || el.ticket.status === 'Imported') ? 'Active' : el.ticket.status;
          return el;
        }),
        pending: false,
        passes_modal_open: true
      }

    case EDIT_MEMBER_PASSES_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_MEMBER_TICKET:
      console.log('Update member ticket Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_MEMBER_TICKET_SUCCESS:
      message.success('Member updated ticket successfully');
      return {
        ...state,
        pass_list: payload.map(el => {
          el.ticket.status = (el.ticket.status === 'Created' || el.ticket.status === 'Imported') ? 'Active' : el.ticket.status;
          return el;
        }),
        pending: false,
      }

    case UPDATE_MEMBER_TICKET_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case GET_OSS_TOKEN_SUCCESS:
      let client = new OSS({
        region: config.oss.region,
        bucket: config.oss.bucket,
        accessKeyId: payload.AccessKeyId,
        accessKeySecret: payload.AccessKeySecret,
        stsToken: payload.SecurityToken
      });
      return { ...state, oss_cred: payload, OSSclient: client };

    default:
      return state;
  }
}
