import { message } from "antd";
import dayjs from 'dayjs';
import { OPEN_BOOKING_MODAL, RESET_BOOKING, ADD_BOOKING, ADD_BOOKING_SUCCESS, ADD_BOOKING_ERROR, FETCH_BOOKINGS, FETCH_BOOKINGS_SUCCESS, FETCH_BOOKINGS_ERROR, DELETE_BOOKING, DELETE_BOOKING_SUCCESS, DELETE_BOOKING_ERROR } from "../../constants/ActionTypes";

const default_booking = {
  _id: null,
  title: null,
  organizer: null,
  email: null,
  location: null,
  start_time: null,
  end_time: null,
  attendees: [],
  note: '',
}

const INIT_STATE = {
  pending: false,
  refresh: false,
  edit: false,
  booking_modal_open: false,
  booking: default_booking,
  count: 0,
  bookings: [],
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case FETCH_BOOKINGS:
      return { ...state, pending: true, refresh: true }

    case FETCH_BOOKINGS_SUCCESS:
      const bookings = payload.bookings.map(item => {
        item.start_time = new Date(item.start_time);
        item.end_time = new Date(item.end_time);
        item.duration = dayjs(item.end_time).diff(dayjs(item.start_time), 'hour', true).toFixed(1);
        return item;
      });
      return { ...state, pending: false, bookings: payload.bookings, count: payload.count, refresh: false }

    case FETCH_BOOKINGS_ERROR:
      return { ...state, pending: false, refresh: false }

    case RESET_BOOKING:
      return { ...state, pending: false, booking: { ...default_booking }, edit: false, booking_modal_open: false }

    case OPEN_BOOKING_MODAL:
      return { ...state, booking_modal_open: payload }

    case ADD_BOOKING:
      return { ...state, pending: true }

    case ADD_BOOKING_SUCCESS:
      message.success('New booking added successfully');
      return { ...state, pending: false, booking_modal_open: false, refresh: true };

    case ADD_BOOKING_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_BOOKING:
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_BOOKING_SUCCESS:
      message.success('Booking deleted successfully');
      return { ...state,  booking: { ...default_booking }, pending: false, booking_modal_open: false, edit: false, refresh: true }

    case DELETE_BOOKING_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    default:
      return state;
  }
}
